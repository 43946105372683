<template>
    <a-modal :visible="visible" title="Ajouter un sondage" ok-text="Ajouter" @ok="submitForm" @cancel="resetForm">
        <a-form ref="formRef" :model="dynamicValidateForm" v-bind="formItemLayoutWithOutLabel">
            <a-tabs  
                v-model:activeKey="activeKey" 
                default-active-key="1" 
                size="large"
            >
                <a-tab-pane key="1" tab="Titre">
                  <a-form-item  
                    name="nameFr"
                    :rules="{
                        required:true,
                        message:'Le titre est requis',
                        trigger:'change'
                    }"                    
                    >
                    <a-input v-model:value="dynamicValidateForm.nameFr" placeholder="Titre" />
                  </a-form-item>        
                </a-tab-pane>
                <a-tab-pane key="2" tab="عنوان">
                  <a-form-item name="nameAr">
                    <a-input v-model:value="dynamicValidateForm.nameAr" placeholder="عنوان" />
                  </a-form-item>        
                </a-tab-pane>
                <a-tab-pane key="3" tab="Title">
                  <a-form-item name="nameEn">
                    <a-input v-model:value="dynamicValidateForm.nameEn" placeholder="Title" />
                  </a-form-item>        
                </a-tab-pane>      
            </a-tabs>            
            <a-form-item
            v-for="(question, index) in dynamicValidateForm.questions"
            :key="question.key"
            v-bind="index === 0 ? formItemLayout : {}"
            :label="index === 0 ? 'questions' : ''"
            :name="['questions', index, 'value','question']"
            :rules="{
                required: true,
                message: 'La question ne peut pas être nulle',
                trigger: 'change',
            }"
            >
                <a-input
                    v-model:value="question.value.question"
                    placeholder="Veuillez saisir la question"
                    style="width: 60%; margin-right: 8px"
                />
                <MinusCircleOutlined
                    v-if="dynamicValidateForm.questions.length > 1"
                    class="dynamic-delete-button"
                    :disabled="dynamicValidateForm.questions.length === 1"
                    @click="removequestion(question)"
                />
                <br/>
                <div 
                    v-for="option in question.value.options"
                    :key="option.key"
                    class="ml-5"                    
                >
                    <a-input
                        v-model:value="option.value"
                        placeholder="Veuillez saisir l'option"
                        style="width: 60%; margin-right: 8px"
                    />
                    <MinusCircleOutlined
                        v-if="question.value.options.length > 1"
                        class="dynamic-delete-button"
                        :disabled="question.value.options.length === 1"
                        @click="removeOption(question.key,option.key)"
                    />
                </div> 
                <div class="ml-5">
                    <a-button type="dashed" style="width: 60%" @click="addOption(question.key)">
                        <PlusOutlined />
                        Ajouter option
                    </a-button>                    
                </div>                               
            </a-form-item>
            <a-form-item v-bind="formItemLayoutWithOutLabel">
                <a-button type="dashed" style="width: 60%" @click="addquestion">
                    <PlusOutlined />
                    Ajouter question
                </a-button>
            </a-form-item>
      </a-form>
    </a-modal>
</template>
<script>
/* eslint-disable */
import { defineComponent, toRefs, reactive, ref, toRaw } from 'vue'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons-vue';
import {useRouter} from 'vue-router'
import ApiClient from '@/services/axios'
import { message } from 'ant-design-vue';

export default defineComponent({
    components: {
        MinusCircleOutlined,
        PlusOutlined,
    },    
    props:['visible'],
    setup(props, { emit }) {
        const router = useRouter()
        const {visible} = toRefs(props)
        const formRef = ref();
        const activeKey = ref()
        const formItemLayout = {
        labelCol: {
            xs: {
            span: 24,
            },
            sm: {
            span: 4,
            },
        },
        wrapperCol: {
            xs: {
            span: 24,
            },
            sm: {
            span: 20,
            },
        },
        };
        const formItemLayoutWithOutLabel = {
        wrapperCol: {
            xs: {
            span: 24,
            offset: 0,
            },
            sm: {
            span: 20,
            offset: 4,
            },
        },
        };
        const dynamicValidateForm = reactive({
            questions: [],
            nameFr:"",
            nameEn:'',
            nameAr:''
        });
        const transformData = (raw)=>{
            let data = {
                name:{},
                questions:[]
            }
            if(raw.nameFr) data.name['fr'] = raw.nameFr
            if(raw.nameEn) data.name['en'] = raw.nameEn
            if(raw.nameAr) data.name['ar'] = raw.nameAr
            if(raw.questions.length<1) throw new Error('Un question au moins est requis')
            else{
                raw.questions.forEach(question=>{
                    let dataQuestion = {
                        question:question.value.question,
                        options:[]
                    }
                    if(question.value.options.length<1) {
                        message.error("Une option par question au moins est requis")
                        throw new Error('Une option par question au moins est requis')
                    }
                    question.value.options.forEach((option)=>{if (option.value.length>0) dataQuestion.options.push(option.value)})
                    if (dataQuestion.options.length == 0){
                        message.error("Une option par question au moins est requis")
                        throw new Error('Une option par question au moins est requis')
                    }
                    data.questions.push(dataQuestion)
                })
            }
            return data
        }
        const resetForm = () => {
            formRef.value.resetFields();
            dynamicValidateForm.questions = []
            emit('cancelPollAdding')
        };        
        const submitForm = () => {
        formRef.value
            .validate()
            .then(() => {
                const data  = transformData(toRaw(dynamicValidateForm))
                ApiClient.put('/poll',{data})
                    .then((res)=>{
                        emit('addPoll',res.data)
                        resetForm()
                        router.go()
                    })
            })
            .catch((e) => {
                message.error('Impossible d\'ajouter le sondage')
                console.error(e)
            });
        };
        const removequestion = item => {
            let index = dynamicValidateForm.questions.indexOf(item);

            if (index !== -1) {
                dynamicValidateForm.questions.splice(index, 1);
            }
        };
        const addquestion = () => {
            dynamicValidateForm.questions.push({
                value: {
                    'question':'',
                    "options":[]
                },
                key: Date.now(),
            });
        };        
        const removeOption = ( questionKey, optionKey )=>{
            let newQuestionArray =   []
            dynamicValidateForm.questions.forEach((question)=>{
                if(question.key==questionKey)
                {
                    const  oldOptions = question.value.options
                    let  newOptions = []
                    oldOptions.forEach(option=>{
                        if(option.key!=optionKey)
                            newOptions.push(option)
                    })                   
                    newQuestionArray.push({
                        ...question,
                        value:{
                            question:question.value.question,
                            options:newOptions
                        }
                    })

                }
                else newQuestionArray.push(question)
            })
            dynamicValidateForm.questions = newQuestionArray
        }
        const addOption = (key) => {
            dynamicValidateForm.questions.map((question)=>{
                if(question.key==key)
                    return {
                        ...question,
                        value:{
                            ...question.value,
                            options:question.value.options.push({value: '',key: Date.now()})
                        }
                    }
                else return question
            })
        };        



        return {
            formRef,
            formItemLayout,
            formItemLayoutWithOutLabel,
            dynamicValidateForm,
            submitForm,
            resetForm,
            removequestion,
            removeOption,
            addquestion,
            addOption,
            visible,
            activeKey
        }
    },
})
</script>
<style>
.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
</style>