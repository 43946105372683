<template>
  <div class="container">
    <h1 class="center">{{ data.poll.name ? data.poll.name.fr : '' }}</h1>
    <div v-for="(row, index) in data.poll.questions" :key="'row' + index" class="row">
      <vue-echarts
        :ref="'row-' + index + 'chart-1'"
        :option="getOptions(row)"
        style="height: 300px; width: 400px"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { defineComponent, reactive, watch, toRefs, ref } from 'vue'
import ApiClient from '@/services/axios'
import { VueEcharts } from 'vue3-echarts'

export default defineComponent({
  components: {
    VueEcharts,
  },
  props: {
    pollId: {
      type: String,
      default: () => '',
    },
  },
  setup(props) {
    const { pollId } = toRefs(props)
    let data = reactive({
      poll: {},
    })
    watch(
      () => props.pollId,
      (first, second) => {
        getPollData()
      },
    )
    const chartData = ref([])
    const getPollData = () => {
      ApiClient.post('/poll/filter', { query: { _id: pollId.value } })
        .then((res) => (data.poll = res.data[0]))
        .catch(() => {})
      ApiClient.post('/poll_vote/filter', {
        query: { poll: pollId.value },
        aggregation: [
          {
            $project: {
              answer: 1,
            },
          },
        ],
      })
        .then((res) => {
          const votes = res.data
          data.poll.questions = [...data.poll.questions].map((question) => {
            return {
              ...question,
              votes: question.options.map((_) => 0),
            }
          })
          const addVote = (questionIndex, vote) => {
            let question = { ...data.poll.questions[questionIndex] }
            const optionIndex = question.options.indexOf(vote)
            if (optionIndex != -1) question.votes[optionIndex] += 1
            return question
          }

          votes.forEach((vote) => {
            const { answer } = vote
            for (const [key, value] of Object.entries(answer)) {
              const validKey = parseInt(key)
              console.log(validKey)
              if (typeof validKey !== 'undefined')
                data.poll.questions[validKey] = addVote(validKey, value)
            }
          })
          console.info(data)
        })
        .catch(() => {})
    }

    getPollData()
    const getOptions = (source) => {
      const { question, options, votes } = source
      return {
        title: {
          text: question,
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c}',
        },
        xAxis: {
          type: 'category',
          data: options,
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: votes,
            type: 'bar',
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
                color: 'rgba(0, 0, 2, 0.5)',
              },
            },
            itemStyle: {
            color: 'rgba(204, 27, 53)',
          }
          },
        ],
      }
    }
    return {
      pollId,
      getOptions,
      data,
    }
  },
})
</script>
