<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card-placeholder">
          <vb-headers-tag :data="{ title: 'Gérer les sondages' }" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card-top card-top-danger">
          <div class="card-header py-0">
            <vb-headers-card-header-tabbed :data="{ title: 'Liste des sondages' }" />
          </div>
          <div class="card-body">
             <vb-controls-button-2
              :data="{ title: 'Ajouter sondages', icon: 'fe fe-plus', type: 'primary' }"
              @click="showPollModal"
            />
          </div>
            <div class="card-body">
              <a-table :columns="columns" :data-source="data" row-key="_id" :loading="false" >
                <template
                  #filterDropdown="{
                    setSelectedKeys,
                    selectedKeys,
                    confirm,
                    clearFilters,
                    column,
                  }"
                >
                  <div class="custom-filter-dropdown">
                    <a-input
                      :placeholder="`Search ${column.dataIndex}`"
                      :value="selectedKeys[0]"
                      style="width: 188px; margin-bottom: 8px; display: block"
                      @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                      @pressEnter="() => handleSearch(selectedKeys, confirm)"
                    />
                    <a-button
                      type="primary"
                      size="small"
                      style="width: 90px; margin-right: 8px"
                      @click="() => handleSearch(selectedKeys, confirm)"
                      >Search</a-button
                    >
                    <a-button size="small" style="width: 90px" @click="() => handleReset(clearFilters)"
                      >Reset</a-button
                    >
                  </div>
                </template>  
                <template #filterIcon="{ text: filtered }">
                  <a-icon type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
                </template>                              
                <template #status="{ text }">
                  <span
                    :class="[
                      text === 'inactive'
                        ? 'font-size-12 badge badge-primary'
                        : 'font-size-12 badge badge-success',
                    ]"
                    >{{ text === 'inactive' ? 'Inactive' : 'Active' }}</span
                  >
                </template>      
                <template #name="{ text }">
                  {{ text?text[language.value[language.key]]:""}}
                </template>    
                <template #language>
                  <a-button @click="changeLanguage">
                    <i class="fe fe-globe"></i>
                    {{language.key==0?"Français":(language.key==1?"العربية":"English")}}
                  </a-button>
                </template>                            
                <template #action="{ record }">
                  <span>
                    <a class="btn btn-sm btn-light" @click="changeStatus(record)">
                      <small>
                        <i class="fe fe-trash mr-2" />
                      </small>
                      {{ record.status == 'active' ? 'Désactiver' : 'Activer' }}
                    </a>
                  </span>
                  <span class="ml-2">
                    <a class="btn btn-sm btn-light" @click="vueStat(record)">
                      <small>
                        <i class="fe fe-eye mr-2" />
                      </small>
                      Résultat
                    </a>
                  </span>                  
                </template>
                <template #expandedRowRender="{ record }">
                  <table class="table table-sm table-striped">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Question</th>
                        <th scope="col">Options</th>
                      </tr>
                    </thead>
                    <tbody
                      v-for="(item, index) in record.questions"
                      :key="index"                    
                    >
                    <tr>
                      <th scope="row">{{index}}</th>
                      <td>{{item.question}}</td>
                      <td>
                        <ul 
                          v-for="(option, _) in item.options"
                          :key="_"     
                          class="list-group"
                        >
                          <li class="list-group-item">{{option}}</li>

                        </ul>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </template>     
              </a-table>
            </div>
        </div>
      </div>
    </div>
    <add-poll
      :visible="addPollModal"
      @add="addPoll"
      @cancel="cancelPollAdding"
    />
    <a-modal
      :visible="pollStatsModal"
      title="Résultat du sondage" 
      :width="500"
       @cancel="cancelPollStats"

    >
      <poll-stats
        :poll-id="pollID"
      />
    </a-modal>
  </div>
</template>

<script>
import VbHeadersTag from '@/components/widgets/Headers/Tag'
import VbControlsButton2 from '@/components/widgets/Controls/Button2'
import VbHeadersCardHeaderTabbed from '@/components/widgets/Headers/CardHeader4'
import AddPoll from './components/AddPoll'
import PollStats from './components/PollStats'
import { defineComponent, ref, reactive } from 'vue'
import ApiClient from '@/services/axios'
export default defineComponent({
  name: 'VbPublications',
  components: {
    VbHeadersTag,
    VbHeadersCardHeaderTabbed,
    VbControlsButton2,
    AddPoll,
    PollStats,
    PollStats
  },
  setup(){
        const pollID = ref("")
    const pollStatsModal = ref(false)
    const data = ref([])
    ApiClient.post('/poll/filter',{
      query:{}
    })
      .then((res)=>{data.value = res.data})
      .catch()
    // Table  vars
    const editableData = reactive({})
    const searchText = ref('')
    const searchInput = ref()
    const activeRecord = ref()
    const state = reactive({
      searchText: '',
      searchedColumn: '',
    })
    const columns = [
    {
      width: '10%',
      slots: {
          customRender: 'language',
      },          
    },      
    {
      title: 'Titre',
      dataIndex: 'name',
      width: '50%',
      slots: {
        filterDropdown: 'filterDropdown',
        filterIcon: 'filterIcon',
        customRender: 'name',
      },
      onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => {
            searchInput.value.focus()
          })
        }
      },
    },
    {
      title: 'status',
      width: '20%',
      dataIndex: 'status',
      sorter: (a, b) => a.status.length - b.status.length,
      slots: { customRender: 'status' },
    },
    {
      title: 'Actions',
      width: '20%',
      slots: { customRender: 'action' },
    },
    ]
    // other  vars
    const addPollModal =  ref(false)
    const language = reactive({
        value:["fr","ar","en"],
        key:0
      })
    // functions
      // table functs
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm()
      state.searchText = selectedKeys[0]
      state.searchedColumn = dataIndex
    }

    const handleReset = (clearFilters) => {
      clearFilters()
      searchText.value = ''
    }
    const vueStat = (record) =>{
      pollID.value = record._id
      console.log(pollID.value)
      pollStatsModal.value = true
    }
    const changeStatus = (record) => {
      record.status = record.status == 'active' ? 'inactive' : 'active'
      ApiClient.patch('/poll/' + record._id, {
        data: { status: record.status },
      })
        .then(() => {
          Object.assign(
            data.value.filter((item) => record._id === item._id),
            record,
          )

          message.success(`Sondage est ${record.status == 'inactive' ? 'désactivé' : 'activé'}`)
        })
        .catch((e) => {
          message.warning("Impossible d'activer/désactiver le contenue")
        })
    }      
    const showPollModal = () => addPollModal.value  = true
    const cancelPollAdding = () => addPollModal.value  = false
    
    const cancelPollStats = () => pollStatsModal.value  = false
    const addPoll = (item) => data.value.push(item)
    const changeLanguage = () => {language.key = (language.key+1)%3}
    return {
      columns,
      data,
      editableData,
      searchText,
      searchInput,
      activeRecord,
      state,
      addPollModal,
      addPoll,
      changeStatus,
      showPollModal,
      handleReset,
      handleSearch,
      language,
      changeLanguage,
      cancelPollAdding,
      pollStatsModal,
      cancelPollStats,
      pollID,
      vueStat,
    }
  }
})
</script>
